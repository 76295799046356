/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { phone } from 'react-icons-kit/fa/phone';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Rating from 'common/components/Rating';
import Container from 'common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';

import bannerImg from 'common/assets/image/appModern/banner.png';
import circleBorder from 'common/assets/image/appModern/shape.svg';
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Innowacyjny Software House"
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Jesteśmy Polską firmą. Dlaczego .it? chcielibyśmy, aby nasi kontrahenci kojarzyli nas z technologią informatyczną (information technology), dlatego że w myśl naszej polityki firmy, podstawą dobrego oprogramowania, spełniającą wymagania klientów jest innowacyjność i dbanie o jak najwyższej jakości kod. Dzięki zaangażowaniu programistów którzy wyróżniają się doświadczeniem i aktualną wiedzą, współpraca z nami realizuje indywidualne potrzeby."
            />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup>
              <a href="mailto:biuro@innovcode.it">
                <Button className="primary" title="biuro@innovcode.it" />
              </a>
              <Button
                  className="text"
                  variant="textButton"
                  icon={<Icon icon={phone} />}
                  iconPosition="left"
                  title="(+48) 663 067 607"
              />
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
