import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppModern/Banner';
import DashboardFeatures from 'containers/AppModern/Dashboard';
import Footer from 'containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from 'components/seo';
import Cookie from "../containers/AppModern/Cookie";

export default function () {
  return (
      <ThemeProvider theme={theme}>
        <>
          <SEO title="Innovcode" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>
            <ContentWrapper>
              <Banner />
              <DashboardFeatures />
            </ContentWrapper>
            <Footer />
          </AppWrapper>
          <Cookie />
        </>
      </ThemeProvider>
  );
}
